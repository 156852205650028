.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-adjust {
  font-size: 20px;
  position: relative !important;
  left: -8px;
  top: 2.1px;
}

.stats-h6{
  background: #fff;
  padding: 10px;
}

.login-a{
  text-decoration: none !important;
}

.login-a .text-muted:hover {
  cursor: not-allowed;
}

.search__activity{
  width: 300px;
}
.profileMenu{
  margin-left: 190px;
}